/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconComponent, IconTarget, IconTemplate } from 'components/icons';
import { Flex, Spacings, Text } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import CollapsedEntry from './CollapsedEntry';

export type Section = 'actions' | 'templates';

interface SectionsProps {
	numTemplates: number;
	numActions: number;
	collapsed?: boolean;
	section: Section;
	setSection: (section: Section) => void;
}

export default function Sections({
	section,
	numTemplates,
	numActions,
	collapsed,
	setSection,
}: SectionsProps): ReactElement {
	if (collapsed) {
		return (
			<Flex spacing="xxSmall" style={{ width: '100%' }}>
				<CollapsedEntry isSelected={section === 'actions'} onClick={() => setSection('actions')} tooltip="Actions">
					<IconTarget variant="medium" />
				</CollapsedEntry>
				<CollapsedEntry
					isSelected={section === 'templates'}
					onClick={() => setSection('templates')}
					tooltip="Templates"
				>
					<IconTemplate variant="medium" />
				</CollapsedEntry>
			</Flex>
		);
	}

	return (
		<Flex direction="horizontal" style={{ width: '100%' }}>
			<SectionsItem
				selected={section === 'actions'}
				Icon={IconTarget}
				title={numActions >= 0 ? `Actions (${numActions})` : 'Actions'}
				first
				onClick={() => setSection('actions')}
			/>
			<SectionsItem
				selected={section === 'templates'}
				Icon={IconTemplate}
				title={numTemplates >= 0 ? `Templates (${numTemplates})` : 'Templates'}
				onClick={() => setSection('templates')}
			/>
		</Flex>
	);
}

interface SectionItemProps {
	Icon: IconComponent;
	selected: boolean;
	first?: boolean;
	title: string;
	onClick: () => void;
}

function SectionsItem({ title, Icon, selected, first, onClick }: SectionItemProps): ReactElement {
	const { neutral000, neutral150, neutral300 } = theme.colors;

	return (
		<Flex
			direction="horizontal"
			align="center"
			style={{
				flexGrow: 1,
				padding: `${Spacings.small} ${Spacings.xSmall} ${Spacings.small} ${Spacings.small}`,
				backgroundColor: selected ? neutral000 : neutral150,
				borderRight: first ? `1px solid ${neutral300}` : 'none',
				borderBottom: !selected ? `1px solid ${neutral300}` : 'none',
				onHover: {
					backgroundColor: selected ? neutral000 : neutral300,
				},
			}}
			onClick={onClick}
		>
			<Icon variant="large" color={selected ? 'neutral800' : 'slate'} />
			<Text type="mediumStrong" style={{ marginLeft: Spacings.xxSmall, whiteSpace: 'nowrap' }}>
				{title}
			</Text>
		</Flex>
	);
}
