/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { Flex, Spacings } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import Sidebar from 'targets/Sidebar';
import { ActionVO } from 'ui-api';

import SelectedEntityPopup from './SelectedEntityPopup';
import TemplatesContent from './TemplatesContent';
import Sections, { Section } from './Sections';
import ActionsContent from './ActionsContent';
import { ActionCategoryItem } from './types';
import SearchInput from './SearchInput';
import useActions from '../useActions';

interface StepsSidebarProps {
	height: number;
}

interface SelectedEntity {
	action?: ActionCategoryItem;
	templateId?: string;
}

export default function StepsSidebar({ height }: StepsSidebarProps): ReactElement {
	const [selectedEntity, setSelectedEntity] = useState<SelectedEntity | null>(null);
	const [section, setSection] = useState<Section>('actions');

	const targetDefinitionsResult = useTargetDefinitions();
	const targetDefinitions = targetDefinitionsResult.value || [];

	//  actions
	const actions = useFilteredActions();

	// templates
	const templatesResult = useRefreshingTemplates({
		searchContext: 'SETTINGS',
		pageSize: 1_000_000,
		pathname: '/design',
	});
	const templates = templatesResult.value?.content;

	return (
		<Sidebar
			title="Steps"
			sx={{
				position: 'relative',
				height: `calc(100vh - ${height}px)`,
				backgroundColor: 'neutral000',
				overflowY: 'visible',
				overflow: 'visible',
				boxShadow: '5px 0px 6px rgba(0, 0, 0, 0.1)',
				paddingLeft: Spacings.small,
				paddingRight: Spacings.small,
				zIndex: 2,
			}}
		>
			{(collapsed, setCollapsed) => {
				return (
					<>
						<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
							{selectedEntity && (
								<SelectedEntityPopup
									targetDefinitions={targetDefinitions}
									selectedEntity={selectedEntity}
									onClose={() => setSelectedEntity(null)}
								/>
							)}
							<Flex spacing={collapsed ? 'large' : 'small'} style={{ marginTop: Spacings.small }}>
								<SearchInput
									targetDefinitionsResult={targetDefinitionsResult}
									collapsed={collapsed}
									onExpand={() => setCollapsed(false)}
								/>

								<Flex
									spacing={collapsed ? 'large' : 'none'}
									style={{
										border: collapsed ? 'none' : '1px solid ' + theme.colors.neutral300,
										maxHeight: `calc(100vh - ${height + 160}px)`,
										width: '100%',
									}}
								>
									<Sections
										collapsed={collapsed}
										section={section}
										setSection={(section) => {
											setSection(section);
											setSelectedEntity(null);
										}}
										numTemplates={templates?.length ?? -1}
										numActions={actions.length ?? -1}
									/>

									{section === 'actions' && (
										<ActionsContent
											selectedActionId={selectedEntity?.action?.id}
											targetDefinitions={targetDefinitions}
											collapsed={collapsed}
											actions={actions}
											onActionClick={(action) => setSelectedEntity(action ? { action } : null)}
										/>
									)}
									{section === 'templates' && (
										<TemplatesContent
											selectedTemplateId={selectedEntity?.templateId}
											targetDefinitions={targetDefinitions}
											collapsed={collapsed}
											templates={templates}
											onTemplateClick={(templateId) => setSelectedEntity({ templateId })}
										/>
									)}
								</Flex>
							</Flex>
						</ErrorBoundary>
					</>
				);
			}}
		</Sidebar>
	);
}

function useFilteredActions(): ActionVO[] {
	const [{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam }] = useState(() =>
		createFilterParams('/design'),
	);
	const [{ freeTextPhrases, tags, actions: actionsFromUrl, targetTypes }] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		tagsParam,
	]);

	// actions
	let actions = useActions();
	actions = actions.filter((action) => {
		for (let i = 0; i < freeTextPhrases.length; i++) {
			const freeTextPhrase = freeTextPhrases[i];
			if (!includes(action.name, freeTextPhrase) && !includes(action.technology || '', freeTextPhrase)) {
				return false;
			}
		}

		// matches any
		let matchesAny = actionsFromUrl.length === 0;
		for (let i = 0; i < actionsFromUrl.length; i++) {
			if (action.id === actionsFromUrl[i]) {
				matchesAny = true;
				break;
			}
		}
		if (!matchesAny) {
			return false;
		}

		for (let i = 0; i < tags.length; i++) {
			const tag = tags[i];
			if (!action.hubTags || !action.hubTags.includes(tag)) {
				return false;
			}
		}

		for (let i = 0; i < targetTypes.length; i++) {
			const targetType = targetTypes[i];
			if (!('target' in action) || action.target.type !== targetType) {
				return false;
			}
		}

		return true;
	});

	return actions;
}
