/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { VariableVO, TemplateMetadataVO } from 'ui-api';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { UseTemplateFormData } from './UseTemplateForm';

interface EnrichedMetadata extends TemplateMetadataVO {
	environmentVariables: VariableVO[];
}

interface MetadataSyncJobProps {
	withExperimentHyphotesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
}

export default function MetadataSyncJob({
	withExperimentHyphotesisExtraction,
	withExperimentNameExtraction,
}: MetadataSyncJobProps): null {
	const formik = useFormikContext<UseTemplateFormData>();
	const { id, environmentId } = formik.values;

	const enrichtedMetadataResult = usePromise(async () => {
		const environmentVariables = await Services.environments.fetchEnvironmentVariables(environmentId);
		const metadata = await Services.templatesApi.getTemplateMetadata(
			{
				...formik.values,
				lanes: formik.values.__originalLanes,
				experimentName: withExperimentNameExtraction ? formik.values.__originalExperimentName : '',
				hypothesis: withExperimentHyphotesisExtraction ? formik.values.__originalHypothesis : '',
			},
			environmentId,
		);

		return {
			...metadata,
			environmentVariables,
		} as EnrichedMetadata;
	}, [id, environmentId]);
	const stableMetadata = useStableInstance(enrichtedMetadataResult.value);

	useEffect(() => {
		if (stableMetadata === undefined) {
			return;
		}

		const variables = new Map(
			Object.keys(stableMetadata.variables).map((variable) => [variable, stableMetadata.variables[variable]]),
		);
		const placeholders = new Map(
			Object.keys(stableMetadata.placeholders).map((placeholder) => [
				placeholder,
				stableMetadata.placeholders[placeholder],
			]),
		);

		const placeholderValues = new Map(Array.from(placeholders.keys()).map((key) => [key, '']));
		const variableValuesMap = new Map(Array.from(variables.keys()).map((key) => [key, '']));
		stableMetadata.environmentVariables.forEach(({ key, value }) => {
			variableValuesMap.set(key, value);
		});

		formik.setFieldValue(
			'variables',
			stableMetadata.environmentVariables.map((ev) => ev.key),
		);
		formik.setFieldValue('variablesMap', variables);
		formik.setFieldValue('placeholdersMap', placeholders);
		formik.setFieldValue('placeholderValuesMap', placeholderValues);
		formik.setFieldValue('variableValuesMap', variableValuesMap);
		formik.setFieldValue(
			'placeholders',
			formik.values.placeholders.filter((p) => placeholders.has(p.key)),
		);
	}, [stableMetadata]);

	return null;
}
