/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Message,
	RouterLink,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TextField,
	userConfirmV2,
} from 'components';
import { IconAdd, IconArrowLeft, IconDelete, IconEdit, IconSearch } from 'components/icons';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import ContentWrapper from 'pages/settings/components/ContentWrapper';
import { Flex, Grid, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { localeCompare } from 'utils/string';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';

interface EditEnvironmentVariablesProps {
	environmentId: string;
}

export default function EditEnvironmentVariables({ environmentId }: EditEnvironmentVariablesProps): ReactElement {
	const environmentResult = usePromise(() => Services.environments.fetchEnvironment(environmentId), [environmentId]);

	const [updateVariablesSignal, setUpdateVariablesSignal] = useState(0);
	const environmentVariableResult = usePromise(
		() => Services.environments.fetchEnvironmentVariables(environmentId),
		[environmentId, updateVariablesSignal],
	);

	const environment = environmentResult.value;
	const environmentVariables = environmentVariableResult.value;

	const [searchQuery, setSearchQuery] = useState('');

	return (
		<ContentWrapper>
			<Flex spacing="large">
				<RouterLink to="/settings/environments">
					<Button variant="chromeless" color="neutral600">
						<IconArrowLeft mr="xSmall" />
						Back to Environment list
					</Button>
				</RouterLink>

				{environment && environmentVariables ? (
					<>
						<Flex direction="horizontal" spacing="large" justify="spread" style={{ width: '100%' }}>
							<Text type="xLargeStrong" textEllipsis>
								{environment.name}
							</Text>

							<Flex direction="horizontal" spacing="large" justify="end" style={{ flexGrow: 1 }}>
								<TextField
									placeholder="Search"
									iconLeft={IconSearch}
									value={searchQuery}
									wrapperSx={{
										width: '100%',
										maxWidth: 400,
									}}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
								<Button
									variant="primary"
									onClick={async () => {
										const shouldRefresh = await onAddVariable(environmentVariables, environmentId);
										if (shouldRefresh) {
											setUpdateVariablesSignal((prev) => prev + 1);
										}
									}}
									minWidth={245}
								>
									<IconAdd ml="-xxSmall" mr="xSmall" />
									Add environment variable
								</Button>
							</Flex>
						</Flex>
						<Table width="100%">
							<TableHead>
								<TableRow>
									<TableHeadCell width="auto">Key</TableHeadCell>
									<TableHeadCell width="auto">Value</TableHeadCell>
									<TableHeadCell width={90} />
								</TableRow>
							</TableHead>
							<TableBody>
								{environmentVariables
									.slice()
									.sort((v1, v2) => localeCompare(v1.key, v2.key))
									.map((variable) => (
										<TableRow key={variable.key} hoverable={true} height={54}>
											<TableDataCell>
												<Text
													textEllipsis
													type="smallStrong"
													style={{
														onHover: {
															color: theme.colors.slate,
															textDecoration: 'underline',
															cursor: 'pointer',
														},
													}}
													onClick={async () => {
														const shouldRefresh = await onEditVariable(environmentVariables, variable, environmentId);
														if (shouldRefresh) {
															setUpdateVariablesSignal((prev) => prev + 1);
														}
													}}
												>
													{`{{${variable.key}}}`}
												</Text>
											</TableDataCell>
											<TableDataCell>
												<Text textEllipsis>{variable.value}</Text>
											</TableDataCell>
											<TableDataCell>
												<ButtonIcon
													tooltip="Edit variable"
													onClick={async () => {
														const shouldRefresh = await onEditVariable(environmentVariables, variable, environmentId);
														if (shouldRefresh) {
															setUpdateVariablesSignal((prev) => prev + 1);
														}
													}}
												>
													<IconEdit />
												</ButtonIcon>
												<ButtonIcon
													onClick={async () => {
														const shouldRefresh = await onDeleteVariable(environmentVariables, variable, environmentId);
														if (shouldRefresh) {
															setUpdateVariablesSignal((prev) => prev + 1);
														}
													}}
													tooltip="Delete variable"
												>
													<IconDelete />
												</ButtonIcon>
											</TableDataCell>
										</TableRow>
									))}
								{environmentVariables.length === 0 && (
									<TableRow>
										<TableDataCell colSpan={3}>
											<Text style={{ color: theme.colors.neutral600 }}>No variables found.</Text>
										</TableDataCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</>
				) : (
					<>
						<Skeletons widths={[300]} height={30} />
					</>
				)}
			</Flex>
		</ContentWrapper>
	);
}

async function onAddVariable(variables: VariableVO[], environmentId: string): Promise<boolean> {
	if (
		(await userConfirmV2({
			title: 'Add new environment variable',
			message: ({ setDisabled }) => <AddMessage variables={variables} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Add variable', variant: 'primary' }],
		})) === 'confirm'
	) {
		const key = (document.getElementById('env-variable-key') as HTMLInputElement)?.value;
		const value = (document.getElementById('env-variable-value') as HTMLInputElement)?.value;

		try {
			if (key && value) {
				await Services.environments.updateEnvironmentVariables(environmentId, {
					variables: variables.concat({ key, value }),
				});
				return true;
			}
		} catch (err) {
			console.error(err);
		}
	}
	return false;
}

interface AddMessageProps {
	variables: VariableVO[];
	setDisabled: (disabled: boolean) => void;
}

function AddMessage({ variables, setDisabled }: AddMessageProps): ReactElement {
	const [key, setKey] = useState('');
	const [value, setValue] = useState('');

	useEffect(() => {
		setDisabled(!key || !value || variables.some((v) => v.key === key));
	}, [key, value, setDisabled]);

	return (
		<Flex spacing="medium" style={{ width: '100%' }}>
			<Text type="medium">You can use this variable across all experiments within this environment.</Text>
			<Grid
				cols="2fr 3fr"
				align="end"
				spacing="medium"
				style={{
					padding: '20px',
					width: '-webkit-fill-available',
					backgroundColor: theme.colors.neutral100,
					borderRadius: 'xSmall',
				}}
			>
				<Flex spacing="xxSmall">
					<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
						Key
					</Text>

					<Flex direction="horizontal" style={{ width: '100%' }}>
						<PlaceholderMarker marker="{{" left />
						<TextField
							id="env-variable-key"
							placeholder="Key"
							value={key}
							onChange={(e) => setKey(e.target.value)}
							sx={{
								borderRadius: 0,
								borderLeft: 'none',
								borderRight: 'none',
							}}
						/>
						<PlaceholderMarker marker="}}" />
					</Flex>
				</Flex>
				<Flex spacing="xxSmall">
					<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
						Value
					</Text>
					<TextField
						id="env-variable-value"
						placeholder="Value"
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
				</Flex>
			</Grid>
		</Flex>
	);
}

async function onEditVariable(variables: VariableVO[], variable: VariableVO, environmentId: string): Promise<boolean> {
	if (
		(await userConfirmV2({
			title: 'Edit environment variable',
			width: '80vw',
			message: ({ setDisabled }) => <EditMessage variable={variable} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Save changes', variant: 'primary' }],
		})) === 'confirm'
	) {
		const key = (document.getElementById('env-variable-key') as HTMLInputElement)?.value;
		const value = (document.getElementById('env-variable-value') as HTMLInputElement)?.value;

		try {
			if (key && value) {
				await Services.environments.updateEnvironmentVariables(environmentId, {
					variables: variables.map((v) => (v.key === variable.key ? { key, value } : v)),
				});
				return true;
			}
		} catch (err) {
			console.error(err);
		}
	}
	return false;
}

interface EditMessageProps {
	variable: VariableVO;
	setDisabled: (disabled: boolean) => void;
}

function EditMessage({ variable, setDisabled }: EditMessageProps): ReactElement {
	const [key, setKey] = useState(variable.key);
	const [value, setValue] = useState(variable.value);

	useEffect(() => {
		setDisabled(!key || !value);
	}, [key, value, setDisabled]);

	return (
		<Flex spacing="large" style={{ width: '100%' }}>
			<Message variant="warning" title="Be careful!" width="100%">
				Changing these values could affect all the experiments using the same environment variable, leading to potential
				errors.
			</Message>

			<Grid
				cols="2fr 3fr"
				align="end"
				spacing="medium"
				style={{
					padding: '20px',
					width: '-webkit-fill-available',
					backgroundColor: theme.colors.neutral100,
					borderRadius: 'xSmall',
				}}
			>
				<Flex spacing="xxSmall">
					<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
						Key
					</Text>
					<Text type="small">This is used for the code</Text>

					<Flex direction="horizontal" style={{ width: '100%' }}>
						<PlaceholderMarker marker="{{" left />
						<TextField
							id="env-variable-key"
							placeholder="Key"
							value={key}
							onChange={(e) => setKey(e.target.value)}
							sx={{
								borderRadius: 0,
								borderLeft: 'none',
								borderRight: 'none',
							}}
						/>
						<PlaceholderMarker marker="}}" />
					</Flex>
				</Flex>
				<Flex spacing="xxSmall">
					<Text type="mediumStrong" style={{ color: theme.colors.neutral800 }}>
						Value
					</Text>
					<Text type="small">This is used to display a human name</Text>
					<TextField
						id="env-variable-value"
						placeholder="Value"
						value={value}
						onChange={(e) => setValue(e.target.value)}
					/>
				</Flex>
			</Grid>
		</Flex>
	);
}

async function onDeleteVariable(
	variables: VariableVO[],
	variable: VariableVO,
	environmentId: string,
): Promise<boolean> {
	if (
		(await userConfirmV2({
			title: 'Delete environment variable',
			message: ({ setDisabled }) => <DeleteMessage variable={variable} setDisabled={setDisabled} />,
			actions: [{ value: 'confirm', label: 'Delete environment variable', variant: 'primary' }],
		})) === 'confirm'
	) {
		try {
			await Services.environments.updateEnvironmentVariables(environmentId, {
				variables: variables.filter((v) => v.key !== variable.key),
			});
			return true;
		} catch (err) {
			console.error(err);
		}
	}
	return false;
}

function DeleteMessage({ variable, setDisabled }: EditMessageProps): ReactElement {
	const [value, setValue] = useState('');
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setDisabled(value !== variable.key || !checked);
	}, [checked, value, setDisabled]);

	return (
		<Flex spacing="medium">
			<Flex direction="horizontal">
				<Text type="medium">Are you sure you want to delete the environment variable</Text>
				<Text type="mediumStrong" style={{ marginLeft: '4px' }}>{`{{${variable.key}}}`}</Text>
				<Text type="medium">?</Text>
			</Flex>

			<Text type="medium">
				Once you delete it, you will have errors in all the experiments that are using this variable.
			</Text>

			<Flex spacing="xSmall">
				<Text
					type="mediumStrong"
					style={{
						color: theme.colors.neutral800,
					}}
				>
					Type the variable’s key “{variable.key}” to confirm deletion
				</Text>
				<TextField placeholder="Variable key" value={value} onChange={(e) => setValue(e.target.value)} />

				<Flex direction="horizontal" spacing="xSmall" style={{ width: '100%', color: theme.colors.neutral800 }}>
					<Checkbox
						data-cy="delete-team-checkbox"
						onChange={(e) => setChecked(e.target.checked)}
						checked={checked}
						minWidth={18}
						minHeight={18}
						mt={2}
						id={'delete-team-checkbox'}
					/>
					<Text type="medium">
						I’m aware that deleting this variable will result in errors in all the experiments that are using this
						variable.
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}
