/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { IconDelay, IconHandle } from 'components/icons';
import { Flex } from '@steadybit/ui-components-lib';
import { Target } from 'pages/components/Target';
import { ReactElement, useState } from 'react';
import { ActionIcon } from 'hocs/ActionIcon';
import { theme } from 'styles.v2/theme';
import { Text } from 'components';

import { SidebarActionMaker, WorkspaceLaneMaker, correctDropAnimation } from '../DragAndDropHandler';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import ActionIconWithBorder from './ActionIconWithBorder';
import CollapsedEntry from './CollapsedEntry';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';

interface ActionsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	actions?: ActionCategoryItem[];
	selectedActionId?: string;
	collapsed?: boolean;
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function Actions({
	targetDefinitions,
	selectedActionId,
	collapsed,
	actions,
	onActionClick,
}: ActionsProps): ReactElement | null {
	const [fixedId] = useState(Math.random());
	const disabled = useIsExperimentDisabled();
	const availableActions = useActions();

	if (!actions) {
		return null;
	}

	return (
		<Droppable droppableId={'droppable' + fixedId} isDropDisabled type="steps">
			{(droppableProvided) => (
				<div
					ref={droppableProvided.innerRef}
					style={{
						display: collapsed ? 'flex' : 'grid',
						flexDirection: collapsed ? 'column' : undefined,
						gridTemplateColumns: '1fr 1fr',
						width: '100%',
						gap: '4px',
					}}
				>
					{actions.map((_action, i) => {
						const { id, label } = _action;
						const action = availableActions.find((a) => a.id === id);
						if (disabled) {
							return (
								<Action key={id} id={id} label={label} action={action} targetDefinitions={targetDefinitions} disabled />
							);
						}

						return (
							<Draggable key={SidebarActionMaker + id} draggableId={SidebarActionMaker + id} index={i}>
								{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
									const isDraggingOverLane: boolean =
										dragSnapshot.draggingOver && dragSnapshot.draggingOver.startsWith(WorkspaceLaneMaker)
											? true
											: false;

									const isDragging = dragSnapshot.isDragging;
									if (isDragging && selectedActionId) {
										onActionClick(null);
									}

									return (
										<div
											ref={dragProvided.innerRef}
											{...dragProvided.draggableProps}
											{...dragProvided.dragHandleProps}
											style={correctDropAnimation(dragSnapshot, dragProvided.draggableProps.style)}
											onClick={isDragging ? undefined : () => onActionClick(_action)}
										>
											<Action
												key={id}
												id={id}
												isDraggingOverLane={isDraggingOverLane}
												targetDefinitions={targetDefinitions}
												isSelected={selectedActionId === id}
												isDragging={isDragging}
												collapsed={collapsed}
												action={action}
												label={label}
											/>
										</div>
									);
								}}
							</Draggable>
						);
					})}

					{droppableProvided.placeholder}
				</div>
			)}
		</Droppable>
	);
}

interface ActionProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	action: ActionVO | undefined;
	isDraggingOverLane?: boolean;
	isDragging?: boolean;
	isSelected?: boolean;
	collapsed?: boolean;
	disabled?: boolean;
	label: string;
	id: string;
}

function Action({
	isDraggingOverLane,
	targetDefinitions,
	isSelected,
	isDragging,
	collapsed,
	disabled,
	action,
	label,
	id,
}: ActionProps): ReactElement {
	if (collapsed) {
		return (
			<CollapsedEntry isSelected={isSelected} onClick={() => {}} tooltip={id === 'wait' ? 'Wait' : label}>
				{id === 'wait' ? <IconDelay variant="medium" /> : <ActionIcon variant="medium" id={id} />}
			</CollapsedEntry>
		);
	}

	const { slate, purple100, neutral000, neutral100, neutral400, neutral500, neutral700 } = theme.colors;

	return (
		<Flex
			spacing="xSmall"
			style={{
				gap: '8px',
				padding: '10px 8px',
				width: '136px',

				backgroundColor: isDraggingOverLane || isSelected ? purple100 : neutral100,
				borderRadius: 'xSmall',
				border: isDraggingOverLane ? '2px dashed' : '2px solid',
				borderColor: isDraggingOverLane || isSelected ? slate : isDragging ? neutral400 : neutral000,

				onHover: {
					borderColor: slate,
					backgroundColor: neutral100,
				},
			}}
		>
			<Flex direction="horizontal" align="center" spacing="xSmall" style={{ width: '100%' }}>
				<ActionIconWithBorder action={action} small />
				<Text
					variant="xSmallStrong"
					color="neutral800"
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						wordBreak: 'normal',
						display: '-webkit-box !important',
						WebkitLineClamp: '2',
						WebkitBoxOrient: 'vertical',
						whiteSpace: 'break-spaces',
					}}
				>
					{label}
				</Text>

				{!disabled && (
					<IconHandle
						ml="auto"
						color={isDragging ? neutral700 : neutral500}
						sx={{
							minWidth: '16px',
							maxWidth: '16px',
							minHeight: '16px',
							maxHeight: '16px',
						}}
					/>
				)}
			</Flex>

			{action && 'target' in action && action.target.type && (
				<Flex direction="horizontal" align="center" spacing="xSmall" style={{ maxWidth: '100%' }}>
					<Target targetId={action.target.type} targetDefinitions={targetDefinitions} small />
				</Flex>
			)}
		</Flex>
	);
}
