/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, BaseExperimentStepVOUnion, ExperimentStepActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { getActionsAndTargets } from 'pages/templates/FromTemplateModal/TemplateDetails/TemplateDetails';
import ExperimentPreview from 'templates/components/TemplateCard/ExperimentPreview';
import Actions from 'pages/templates/FromTemplateModal/TemplateDetails/Actions';
import Targets from 'pages/templates/FromTemplateModal/TemplateDetails/Targets';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import Tags from 'pages/templates/components/TemplateTags';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { convertDurationToSeconds } from 'utils/duration';
import { IconClose, IconNewTab } from 'components/icons';
import Markdown from 'components/Markdown/Markdown';
import { usePromise } from 'utils/hooks/usePromise';
import { Target } from 'pages/components/Target';
import { Services } from 'services/services';
import { Button, Link } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import ActionIconWithBorder from './ActionIconWithBorder';
import { ActionCategoryItem } from './types';
import useActions from '../useActions';

export interface SelectedEntity {
	action?: ActionCategoryItem;
	templateId?: string;
}

interface SelectedEntityPopupProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedEntity: SelectedEntity;
	onClose: () => void;
}

export default function SelectedEntityPopup({
	targetDefinitions,
	selectedEntity,
	onClose,
}: SelectedEntityPopupProps): ReactElement | null {
	const actions = useActions();

	if (!selectedEntity.action && !selectedEntity.templateId) {
		return null;
	}

	return (
		<div
			style={{
				position: 'absolute',
				top: '137px',
				bottom: '24px',
				left: 'calc(100% - 8px)',
				pointerEvents: 'none',
			}}
		>
			<Flex
				spacing="small"
				style={{
					backgroundColor: theme.colors.neutral000,
					boxShadow: '0px 0px 7px 5px rgba(0, 0, 0, 0.1)',
					minWidth: '300px',
					maxHeight: '100%',
					pointerEvents: 'auto',
				}}
			>
				<IconClose
					variant="large"
					sx={{
						position: 'absolute',
						top: '6px',
						right: '6px',
						cursor: 'pointer',
						':hover': { color: theme.colors.neutral500 },
					}}
					onClick={onClose}
				/>
				<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
					<Text
						type="smallStrong"
						style={{
							margin: '24px 24px 0 24px',
						}}
					>
						{selectedEntity.action?.id ? 'Action' : 'Template'} details
					</Text>
					{selectedEntity.action?.id && (
						<ActionDetails action={selectedEntity.action} targetDefinitions={targetDefinitions} />
					)}
					{selectedEntity.templateId && (
						<TemplateDetails
							templateId={selectedEntity.templateId}
							targetDefinitions={targetDefinitions}
							actions={actions}
						/>
					)}
				</ErrorBoundary>
			</Flex>
		</div>
	);
}

interface TemplateDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	actions: ActionVO[];
	templateId: string;
}

function TemplateDetails({ targetDefinitions, actions, templateId }: TemplateDetailsProps): ReactElement {
	const templateResult = usePromise(() => Services.templatesApi.getTemplate(templateId), [templateId]);

	if (!templateResult.value) {
		return (
			<Flex align="center" justify="center" style={{ width: '192px', padding: '24px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const template = templateResult.value;
	const [allActions, allTargets] = getActionsAndTargets(template);

	const content = (
		<>
			<ExperimentPreview
				lanes={template.lanes.map((lane) => ({
					...lane,
					steps: lane.steps.map((step) => ({
						...step,
						actionId: isExperimentStepActionVO(step) ? step.actionId : 'wait',
						durationInSeconds: convertDurationToSeconds(step.parameters.duration) || 0,
					})),
				}))}
				actions={actions}
				disabled={false}
			/>
			<Tags tags={template.tags} />
			<Markdown content={template.templateDescription} />
			<Flex spacing="medium">
				<Targets targets={allTargets} targetDefinitions={targetDefinitions} />
				<Actions actions={allActions} actionDefinitions={actions} />
			</Flex>
		</>
	);

	if (!template.hubLink) {
		return (
			<Flex style={{ padding: '24px' }}>
				<Flex
					spacing="small"
					style={{
						minWidth: '300px',
						maxWidth: '500px',
						overflowX: 'hidden',
						overflowY: 'auto',
					}}
				>
					{content}
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex style={{ padding: '24px' }}>
			<Flex
				spacing="small"
				style={{
					position: 'relative',
					minWidth: '300px',
					maxWidth: '500px',
					maxHeight: '400px',
					overflow: 'hidden',
				}}
			>
				<ShadowOverlay />
				{content}
			</Flex>

			<DetailLink href={`${template.hubLink}/templateRedirect/${template.id}`} />
		</Flex>
	);
}

function isExperimentStepActionVO(step: BaseExperimentStepVOUnion): step is ExperimentStepActionVO {
	return (step as ExperimentStepActionVO).actionId !== undefined;
}

interface ActionDetailsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	action: ActionCategoryItem;
}

function ActionDetails({ action, targetDefinitions }: ActionDetailsProps): ReactElement {
	const actionResult = usePromise(() => Services.actions.findAction(action.id), [action.id]);

	if (actionResult.loading) {
		return (
			<Flex align="center" justify="center" style={{ width: 'calc(100% - 48px)', padding: '12px 24px 32px' }}>
				<LoadingIndicator color="slate" />
			</Flex>
		);
	}

	const actionVO = actionResult.value;
	if (!actionVO || !actionVO.hubSummary) {
		return (
			<Flex spacing="small" style={{ width: 'calc(100% - 48px)', padding: '24px' }}>
				<Flex direction="horizontal" spacing="xSmall">
					<ActionIconWithBorder action={actionVO} />
					<Text type="mediumStrong">{action.label}</Text>
				</Flex>

				<Text type="medium">{actionVO ? actionVO.description : 'No description available'}</Text>
			</Flex>
		);
	}

	const content = (
		<>
			<Flex direction="horizontal" spacing="xSmall">
				<ActionIconWithBorder action={actionVO} />
				<Text type="mediumStrong">{action.label}</Text>
			</Flex>

			<Tags tags={actionVO.hubTags} />

			<Markdown content={actionVO.hubSummary} small />

			{'target' in actionVO && actionVO.target.type && (
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<Text type="smallStrong" style={{ color: theme.colors.neutral600 }}>
						Target:
					</Text>
					<Target targetId={actionVO.target.type} targetDefinitions={targetDefinitions} />
				</Flex>
			)}
		</>
	);

	if (!actionVO.hubLink) {
		return (
			<Flex
				spacing="xSmall"
				style={{
					padding: '24px',
					minWidth: '300px',
					maxWidth: '500px',
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				{content}
			</Flex>
		);
	}

	return (
		<Flex style={{ padding: '24px' }}>
			<Flex
				spacing="small"
				style={{
					position: 'relative',
					minWidth: '300px',
					maxWidth: '500px',
					maxHeight: '400px',
					overflow: 'hidden',
				}}
			>
				<ShadowOverlay />
				{content}
			</Flex>

			<DetailLink href={actionVO.hubLink} />
		</Flex>
	);
}

function ShadowOverlay(): ReactElement {
	return (
		<div
			style={{
				position: 'absolute',
				left: '0',
				right: '0',
				bottom: '0',
				height: '150px',
				background: 'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 10%, rgb(255 255 255 / 0%) 100%)',
				zIndex: 1,
			}}
		/>
	);
}

function DetailLink({ href }: { href: string }): ReactElement {
	return (
		<Link href={href} external>
			<Button variant="secondarySmall">
				<IconNewTab ml="-xSmall" mr="xSmall" />
				Details
			</Button>
		</Link>
	);
}
