/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, PluralLabelVO, TableVO, TargetTypeDescriptionVO } from 'ui-api';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import { useStableInstance } from 'utils/hooks/useStableInstance';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { Dictionary, groupBy } from 'lodash';
import { getLabel } from 'i18n/label';
import { useMemo } from 'react';

export interface ActionCategory {
	subCategories?: ActionSubCategory[];
	actions?: ActionCategoryItem[];
	label: string;
}

interface ActionSubCategory {
	actions: ActionCategoryItem[];
	label: string;
}

export interface ActionCategoryItem {
	action: ActionVO;
	label: string;
}

export type Grouping = 'technology' | 'targetType';

const otherTargetType: TargetTypeDescriptionVO = {
	id: 'other',
	version: '1',
	label: {
		one: 'Other',
		other: 'Others',
	} as PluralLabelVO,
	table: {} as TableVO,
};

export default function useActionHierarchy({
	grouping = 'technology',
	actions,
}: {
	actions: ActionVO[];
	grouping?: Grouping;
}): ActionCategory[] {
	const waitCategory: ActionCategory = {
		label: '',
		actions: actions
			.filter((a) => a.id === 'wait')
			.map((waitAction) => ({
				action: waitAction,
				label: 'Wait',
			})),
	};

	actions = actions.filter((a) => a.id !== 'wait');
	const actionsPerKind = useActionsPerKind(actions.filter((a) => a.kind !== 'ATTACK'));
	const targetDefinitions = useTargetDefinitions();

	if (grouping === 'technology') {
		const groupedActions = groupBy(
			actions.filter((a) => a.kind !== 'ATTACK' || !!targetDefinitions.value?.find((t) => t.id === a.target.type)),
			(action) => action.technology,
		);
		return [waitCategory].concat(
			Object.entries(groupedActions)
				.map(([technology, actions]) => {
					return createActionGroup(technology === 'undefined' ? 'Other' : technology, actions, categoryExtractor);
				})
				.sort((a, b) => localeCompareIgnoreCase(a.label, b.label)),
		);
	}

	const categories: ActionCategory[] = [];
	if (actionsPerKind['CHECK']) {
		categories.push(createActionGroup('Checks', actionsPerKind['CHECK'], technologyExtractor));
	}
	if (actionsPerKind['LOAD_TEST']) {
		categories.push(createActionGroup('Load Tests', actionsPerKind['LOAD_TEST'], technologyExtractor));
	}
	if (actionsPerKind['OTHER']) {
		categories.push(createActionGroup('Other', actionsPerKind['OTHER'], technologyExtractor));
	}

	const actionsPerType = getActionsPerType(
		actions.filter((a) => a.kind === 'ATTACK'),
		otherTargetType.id,
	);

	if (targetDefinitions.value) {
		const actions = targetDefinitions.value
			.slice()
			.concat([otherTargetType])
			.filter((t) => actionsPerType[t.id]?.length > 0)
			.map((t) =>
				createActionGroup(toTitleCase(`${getLabel(t.label, 1)} Attacks`), actionsPerType[t.id], categoryExtractor),
			)
			.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));
		categories.push(...actions);
	}

	return [waitCategory].concat(categories);
}

type SubGroupExtractor = (action: ActionVO) => string;
const categoryExtractor: SubGroupExtractor = (action) =>
	action.category === action.technology ? '' : action.category || '';
const technologyExtractor: SubGroupExtractor = (action) => action.technology || action.category || '';

function createActionGroup(label: string, actions: ActionVO[], subGroupExtractor: SubGroupExtractor): ActionCategory {
	const actionCategory: ActionCategory = { label };

	const [actionsWithoutCategory, subCategories] = groupActions(actions, subGroupExtractor);
	if (actionsWithoutCategory.length > 0) {
		actionCategory.actions = actionsWithoutCategory;
	}
	if (subCategories.length > 0) {
		actionCategory.subCategories = subCategories;
	}
	return actionCategory;
}

function groupActions(
	actions: ActionVO[],
	subGroupExtractor: SubGroupExtractor,
): [ActionCategoryItem[], ActionSubCategory[]] {
	const withCategory: ActionVO[] = [];
	const withoutCategory: ActionVO[] = [];
	actions.forEach((action) => {
		const category = subGroupExtractor(action);
		if (category) {
			withCategory.push(action);
		} else {
			withoutCategory.push(action);
		}
	});

	const actionsWithoutCategory: ActionCategoryItem[] = withoutCategory.map((action) => ({
		label: action.name,
		action,
	}));

	const actionsByCategory = withCategory.reduce(
		(acc, action) => {
			const category = subGroupExtractor(action);
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(action);
			return acc;
		},
		{} as Record<string, ActionVO[]>,
	);

	const actionSubCategories = Object.entries(actionsByCategory)
		.map(([category, actions]) => {
			return {
				label: toTitleCase(category),
				actions: actions
					.map((action) => ({ label: action.name, action }))
					.sort((a, b) => localeCompareIgnoreCase(a.label, b.label)),
			};
		})
		.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));

	// Special case: If there is only one subcategory and no actions without category,
	// return the subcategory directly as actions
	if (actionsWithoutCategory.length === 0 && actionSubCategories.length === 1) {
		return [actionSubCategories[0].actions, []];
	}

	return [actionsWithoutCategory, actionSubCategories];
}

function useActionsPerKind(actions: ActionVO[]): Dictionary<ActionVO[]> {
	const allowedActionIds = actions.map((a) => a.id);
	const stableKey = allowedActionIds.join(',');
	const uniqueActionNamesResult = usePromise<{ [index: string]: string }>(
		async () => Services.actions.getActionNamesWithTargetTypeIfNotUnique(allowedActionIds),
		[stableKey],
	);
	const uniqueActionNames = uniqueActionNamesResult.value ?? {};

	const stableActionName = useStableInstance(uniqueActionNames);

	const actionsPerKind = useMemo(() => getActionPerKind(actions, stableActionName), [stableKey, stableActionName]);
	return actionsPerKind;
}

function getActionsPerType(actions: ActionVO[], fallback: string): Dictionary<ActionVO[]> {
	return groupBy(actions, (attack) => attack.target.type ?? fallback);
}

function getActionPerKind(actions: ActionVO[], uniqueActionNames: { [p: string]: string }): Dictionary<ActionVO[]> {
	return groupBy(
		actions.map((a) => ({ ...a, name: uniqueActionNames[a.id] || a.name })),
		(attack) => attack.kind,
	);
}
