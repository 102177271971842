/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';
import { Stack } from 'components';

import { ActionCategoryItem, ActionSubCategory } from './types';
import ActionSubGroup from './ActionSubGroup';

interface ActionSubGroupsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	subGroups?: ActionSubCategory[];
	selectedActionId?: string;
	collapsed?: boolean;
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionSubGroups({
	targetDefinitions,
	selectedActionId,
	subGroups,
	collapsed,
	onActionClick,
}: ActionSubGroupsProps): ReactElement | null {
	if (!subGroups) {
		return null;
	}

	return (
		<Stack size="xSmall" sx={{ width: '100%' }}>
			{subGroups.map((subGroup) => (
				<ActionSubGroup
					targetDefinitions={targetDefinitions}
					selectedActionId={selectedActionId}
					collapsed={collapsed}
					key={subGroup.label}
					subGroup={subGroup}
					onActionClick={onActionClick}
				/>
			))}
		</Stack>
	);
}
