/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Checkbox,
	Container,
	ContextualMenuButton,
	Link,
	RouterPagination,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	Toggle,
	Tooltip,
	userConfirmV2,
} from 'components';
import {
	IconDelete,
	IconDuplicate,
	IconEdit,
	IconInformation,
	IconNavigationMenuHorizontal,
	IconTrash,
} from 'components/icons';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import FromTemplateDetailsModal from 'pages/templates/FromTemplateModal/TemplateDetailsModal';
import { GetExperimentTemplatePreviewsPageResponse, TemplatePreviewVO } from 'ui-api';
import { createFilterParams } from 'pages/templates/FromTemplateModal/urlParams';
import { ReactElement, ReactNode, RefObject, useState } from 'react';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { deleteTemplate } from 'templates/components/formUtils';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { HistoryWithUrlParams } from 'url/historyWrap';
import { localeCompareIgnoreCase } from 'utils/string';
import { Flex } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { ampli } from 'ampli';

import { directionParam, selectedTemplateIdParam, sortParam, UrlState } from './urlParams';

export default function TemplatesTable(): ReactElement {
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const [templateIdsToDelete, setTemplateIdsToDelete] = useState<string[]>([]);

	const history = useHistory();

	const templatesResult = useRefreshingTemplates({
		searchContext: 'SETTINGS',
		includeNonAvailable: true,
		includeHidden: true,
		pageSize: 1_000_000,
		pathname: '/templates',
	});

	const [{ tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam }] = useState(() =>
		createFilterParams('/templates'),
	);

	const [{ targetTypes, actions, tags, freeTextPhrases, page, sort, direction }, getWithUrlState, updateUrlState] =
		useUrlState<UrlState>([
			selectedTemplateIdParam,
			freeTextPhrasesParam,
			targetTypesParam,
			directionParam,
			actionsParam,
			tagsParam,
			pageParam,
			sortParam,
		]);

	const numOfColumns = 5;

	if (templatesResult.error) {
		return (
			<TemplatesTableWrapper canCreateTemplates={canCreateTemplates}>
				<TableErrorRow error="Error loading templates" />
			</TemplatesTableWrapper>
		);
	}

	if (templatesResult.loading || !templatesResult.value) {
		return (
			<TemplatesTableWrapper canCreateTemplates={canCreateTemplates}>
				<TableLoadingRow numColumns={numOfColumns} />
				<TableLoadingRow numColumns={numOfColumns} />
				<TableLoadingRow numColumns={numOfColumns} />
			</TemplatesTableWrapper>
		);
	}

	function addAll(prev: string[], value: GetExperimentTemplatePreviewsPageResponse | undefined): string[] {
		if (value) {
			return value.content.map((template) => template.id);
		}
		return prev;
	}

	const pageSize = 15;
	let templatesPage = templatesResult.value.content.slice();
	templatesPage.sort((a, b) => {
		const propA = sort === 'name' ? a.templateTitle : sort === 'hubUrl' ? a.hubLink || '' : String(a.created);
		const propB = sort === 'name' ? b.templateTitle : sort === 'hubUrl' ? b.hubLink || '' : String(b.created);
		return direction === 'ASC' ? localeCompareIgnoreCase(propA, propB) : localeCompareIgnoreCase(propB, propA);
	});
	templatesPage = templatesPage.slice(page * pageSize, (page + 1) * pageSize);

	const isSearchDefined = freeTextPhrases.length > 0 || targetTypes.length > 0 || actions.length > 0 || tags.length > 0;

	return (
		<Stack size="large">
			<Stack size="xSmall" alignItems="flex-start">
				{canCreateTemplates && (
					<Button
						disabled={templateIdsToDelete.length === 0}
						variant="chromelessSmall"
						color="neutral600"
						onClick={async () => {
							await handleDeleteClick(templateIdsToDelete, () => {
								setTemplateIdsToDelete([]);
							});
						}}
					>
						<IconDelete mr="xSmall" ml="-xSmall" /> Delete selected templates{' '}
						{templateIdsToDelete.length ? `(${templateIdsToDelete.length})` : ''}
					</Button>
				)}

				<TemplatesTableWrapper
					canCreateTemplates={canCreateTemplates}
					selectAll={
						templatesResult.value.totalElements > 0
							? (all) => setTemplateIdsToDelete((prev) => (all ? addAll(prev, templatesResult.value) : []))
							: undefined
					}
					allSelected={templateIdsToDelete.length === templatesResult.value.totalElements}
					numberOfTemplates={templatesResult.value.totalElements}
				>
					{templatesPage.map((template) => (
						<TemplateRow
							key={template.id}
							template={template}
							history={history}
							canCreateTemplates={canCreateTemplates}
							selectedForDeletion={templateIdsToDelete.includes(template.id)}
							onSelectForDeletion={() => {
								setTemplateIdsToDelete((prev) =>
									prev.includes(template.id) ? prev.filter((id) => id !== template.id) : [...prev, template.id],
								);
							}}
							onSelect={() => {
								updateUrlState({ selectedTemplateId: template.id });
								ampli.experimentTemplateDetailsViewed({
									experiment_template_name: template.templateTitle,
									experiment_template_view_context: 'settings',
								});
							}}
						/>
					))}
					{!templatesResult.value.content.length && (
						<TableRow>
							<TableDataCell colSpan={numOfColumns}>
								<Text muted data-cy="no-templates-found">
									{isSearchDefined ? 'No templates matched your query.' : 'No templates found.'}
								</Text>
							</TableDataCell>
						</TableRow>
					)}
				</TemplatesTableWrapper>
			</Stack>
			<RouterPagination
				activePage={page}
				totalPages={Math.ceil(templatesResult.value.totalElements / pageSize)}
				to={(i) => getWithUrlState({ page: i })}
			/>
		</Stack>
	);
}

function TemplatesTableWrapper({
	canCreateTemplates,
	numberOfTemplates,
	allSelected,
	children,
	selectAll,
}: {
	numberOfTemplates?: number;
	canCreateTemplates: boolean;
	allSelected?: boolean;
	children: ReactNode;
	selectAll?: (all?: boolean) => void;
}): ReactElement {
	const [{ selectedTemplateId, direction, sort }, getWithUrlState, updateUrlState] = useUrlState<UrlState>([
		selectedTemplateIdParam,
		sortParam,
		directionParam,
	]);
	return (
		<>
			{selectedTemplateId && (
				<TemplateDetailsDataLoader
					canCreateTemplates={canCreateTemplates}
					templatePreviewId={selectedTemplateId}
					onClose={() => updateUrlState({ selectedTemplateId: null })}
				/>
			)}

			<Table width="100%">
				<TableHead>
					<TableRow>
						{canCreateTemplates && (
							<TableHeadCell width="110px">
								{selectAll && (
									<Tooltip content={`Select all ${numberOfTemplates} templates on all pages`}>
										<Stack direction="horizontal" size="xSmall" alignItems="center">
											<Checkbox checked={allSelected} onChange={() => selectAll(!allSelected)} flexShrink={0} />
											<Text variant="tableHeader">{allSelected ? 'Deselect all' : 'Select all'}</Text>
										</Stack>
									</Tooltip>
								)}
							</TableHeadCell>
						)}
						<TableHeadCell>
							<TableSortLink
								sort={sort !== 'name' ? undefined : direction === 'DESC' ? 'asc' : 'desc'}
								to={getWithUrlState({
									direction: sort !== 'name' ? 'DESC' : direction === 'DESC' ? 'ASC' : 'DESC',
									sort: 'name',
								})}
							>
								Name
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell width="200px">
							<TableSortLink
								sort={sort !== 'hubUrl' ? undefined : direction === 'DESC' ? 'asc' : 'desc'}
								to={getWithUrlState({
									direction: sort !== 'hubUrl' ? 'DESC' : direction === 'DESC' ? 'ASC' : 'DESC',
									sort: 'hubUrl',
								})}
							>
								From Hub
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell width="210px">
							<TableSortLink
								sort={sort !== 'created' ? undefined : direction === 'DESC' ? 'asc' : 'desc'}
								to={getWithUrlState({
									direction: sort !== 'created' ? 'DESC' : direction === 'DESC' ? 'ASC' : 'DESC',
									sort: 'created',
								})}
							>
								Created
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell width="120px">Hide template</TableHeadCell>
						<TableHeadCell width="50px" />
					</TableRow>
				</TableHead>
				<TableBody>{children}</TableBody>
			</Table>
		</>
	);
}

function TemplateDetailsDataLoader({
	canCreateTemplates,
	templatePreviewId,
	onClose,
}: {
	canCreateTemplates: boolean;
	templatePreviewId: string;
	onClose: () => void;
}): ReactElement {
	const history = useHistory();
	const targetDefinitionsResult = useTargetDefinitions();
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);

	return (
		<FromTemplateDetailsModal
			targetDefinitionsResult={targetDefinitionsResult}
			templatePreviewId={templatePreviewId}
			actionsResult={actionsResult}
			onClose={onClose}
			onGoBack={onClose}
			onEditClick={
				canCreateTemplates
					? (_template) => {
							history.push(`/settings/templates/design/${_template.id}`);
						}
					: undefined
			}
		/>
	);
}

interface TemplateRowProps {
	history: HistoryWithUrlParams<unknown>;
	selectedForDeletion?: boolean;
	template: TemplatePreviewVO;
	canCreateTemplates: boolean;
	onSelectForDeletion?: () => void;
	onSelect: () => void;
}

function TemplateRow({
	selectedForDeletion,
	canCreateTemplates,
	template,
	history,
	onSelectForDeletion,
	onSelect,
}: TemplateRowProps): ReactElement {
	const [optimisticHidden, setOptimisticHidden] = useState<boolean>(template.hidden);
	const [saving, setSaving] = useState<boolean>(false);
	const [hovered, setHovered] = useState(false);

	return (
		<TableRow hoverable={true} height={54} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
			{canCreateTemplates && (
				<TableDataCell width={'30px'}>
					<Checkbox checked={selectedForDeletion} onChange={onSelectForDeletion} onClick={(e) => e.stopPropagation()} />
				</TableDataCell>
			)}
			<TableDataCell maxWidth={'330px'}>
				<Tooltip content={template.templateTitle} onlyShowOnEllipsis>
					<Text
						variant="mediumStrong"
						as="span"
						sx={{
							textDecoration: hovered ? 'underline' : 'none',
							color: hovered ? 'slate' : 'neutral900',
							cursor: 'pointer',
							...textEllipsis,
						}}
						onClick={onSelect}
					>
						{template.templateTitle}
					</Text>
				</Tooltip>
			</TableDataCell>
			<TableDataCell>
				<HubLink template={template} />
			</TableDataCell>
			<TableDataCell>
				{template.imported ? (
					<Flex direction="horizontal" align="center" spacing="xSmall">
						<Text variant="small" sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
							{formatDateWithTime(new Date(template.imported))}
						</Text>
						{template.imported !== template.edited && (
							<Tooltip
								content={
									<Container sx={{ width: 330 }}>
										This template has been modified on {formatDateWithTime(new Date(template.edited))} since it was
										originally imported.
									</Container>
								}
							>
								<div>
									<IconInformation />
								</div>
							</Tooltip>
						)}
					</Flex>
				) : (
					<Text variant="small" sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
						{formatDateWithTime(new Date(template.created))}
					</Text>
				)}
			</TableDataCell>

			<TableDataCell>
				<Toggle
					checked={optimisticHidden}
					onChange={async () => {
						const currentHiddenState = optimisticHidden;
						setOptimisticHidden(!currentHiddenState);
						setSaving(true);

						try {
							const fullExperiment = await Services.templatesApi.getTemplate(template.id);
							await Services.templatesApi.updateTemplate(template.id, {
								...fullExperiment,
								hidden: !currentHiddenState,
								// unused but needed
								creationMethod: 'UI_FROM_SCRATCH',
							});
						} catch {
							setOptimisticHidden(currentHiddenState);
						} finally {
							setSaving(false);
						}
					}}
					disabled={!canCreateTemplates || saving}
				/>
			</TableDataCell>

			<TableDataCell justifyContent="flex-end">
				{canCreateTemplates && (
					<DropDown
						renderComponent={({ setShowMenu, showMenu, ref }) => {
							return (
								<ButtonIcon
									onClick={() => setShowMenu(!showMenu)}
									ref={ref as RefObject<HTMLButtonElement>}
									data-cy="templateActions"
								>
									<IconNavigationMenuHorizontal />
								</ButtonIcon>
							);
						}}
					>
						{({ selectItem }) => (
							<DropdownContentFrame
								sx={{
									p: 'xxSmall',
								}}
							>
								<Stack size="none">
									<ContextualMenuButton
										onClick={async () => {
											const duplicatedTemplate = await Services.templatesApi.duplicateTemplate(template.id);
											history.push(`/settings/templates/design/${duplicatedTemplate.id}`);
										}}
									>
										<IconDuplicate />
										Duplicate Template
									</ContextualMenuButton>

									<Tooltip content="Editing a template doesn't change the experiments created">
										<Container width="100%">
											<ContextualMenuButton
												width="100%"
												onClick={() => history.push(`/settings/templates/design/${template.id}`)}
											>
												<IconEdit />
												Edit Template
											</ContextualMenuButton>
										</Container>
									</Tooltip>

									<ContextualMenuButton
										onClick={async () => {
											selectItem('delete');
											await deleteTemplate(template.id, 'UI_LIST');
										}}
										data-cy="templateActionDelete"
									>
										<IconDelete />
										Delete Template
									</ContextualMenuButton>
								</Stack>
							</DropdownContentFrame>
						)}
					</DropDown>
				)}
			</TableDataCell>
		</TableRow>
	);
}

function HubLink({ template }: { template: TemplatePreviewVO }): ReactElement {
	if (!template.hubName && !template.hubLink) {
		return (
			<Text variant="small" as="span" color="neutral600">
				Created locally
			</Text>
		);
	}

	if (!template.hubLink) {
		return (
			<Text variant="small" as="span" color="neutral800">
				{template.hubName}
			</Text>
		);
	}

	return (
		<Link external href={template.hubLink}>
			<Text variant="small" as="span" color="slate">
				{template.hubName || template.hubLink}
			</Text>
		</Link>
	);
}

async function handleDeleteClick(templateIds: string[], onDeleted: () => void): Promise<void> {
	const entityName = templateIds.length === 1 ? 'template' : 'templates';
	await userConfirmV2({
		title: `Delete ${entityName}`,
		message: (
			<>
				<Stack size="none" mb="medium">
					<Text as="span">
						Are you sure you want to delete {templateIds.length === 1 ? 'this' : 'these'} {entityName}?
					</Text>
					<Text as="span">
						Once deleted, {templateIds.length === 1 ? 'it' : 'they'} won’t be available anymore for future uses.
					</Text>
				</Stack>
				<Text as="span" variant="mediumStrong">
					The experiments created from {templateIds.length === 1 ? 'this' : 'these'} {entityName} won’t be deleted.
				</Text>
			</>
		),
		actions: [
			{
				value: 'confirm',
				label: 'Delete',
				icon: <IconTrash mr="xSmall" ml="-xxxSmall" />,
				variant: 'primary',
				action: async () => {
					await Promise.all(templateIds.map((id) => Services.templatesApi.deleteTemplate(id, 'UI_LIST')));
					Snackbar.dark(entityName + ' deleted');
					onDeleted();
				},
			},
		],
		secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
		width: '700px',
	});
}
