/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Heading,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TextField,
	Tooltip,
} from 'components';
import {
	IconDelete,
	IconEnvironment,
	IconExperiment,
	IconInformation,
	IconInformationCircle,
	IconWarning,
} from 'components/icons';
import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Flex, Grid, Text } from '@steadybit/ui-components-lib';
import { localeCompare } from 'utils/string';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';
import { useState } from 'react';

import { ExperimentFormValues } from '../../experiment';

interface ExperimentAndEnvironmentVariablesProps {
	environmentVariables: VariableVO[];
}

export default function ExperimentAndEnvironmentVariables({
	environmentVariables,
}: ExperimentAndEnvironmentVariablesProps): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();

	const experimentVariables = formik.values.experimentVariables;

	return (
		<Flex spacing="small" style={{ p: 'small', minWidth: '600px' }}>
			<Heading variant="small">Variables</Heading>

			<Flex direction="horizontal" align="center" spacing="xSmall">
				<IconExperiment variant="small" />
				<Text type="small">Experiment variables</Text>
			</Flex>

			<Flex spacing="xSmall">
				<EnvironmentVariable
					environmentVariables={environmentVariables}
					experimentVariables={experimentVariables}
					key={'new-' + experimentVariables.length}
					variable={{ key: '', value: '' }}
					setVariable={(_variable) => {
						const copied = experimentVariables.slice();
						copied.unshift(_variable);
						formik.setFieldValue('experimentVariables', copied);
						formik.setFieldTouched('experimentVariables', true);
					}}
				/>
				{experimentVariables.map((variable, index) => (
					<EnvironmentVariable
						environmentVariables={environmentVariables}
						experimentVariables={experimentVariables.filter((_, i) => i !== index)}
						variable={variable}
						key={variable.key + index}
						setVariable={(_variable) => {
							const copied = [...experimentVariables];
							copied[index] = _variable;
							formik.setFieldValue('experimentVariables', copied);
							formik.setFieldTouched('experimentVariables', true);
						}}
						onDelete={() => {
							formik.setFieldValue(
								'experimentVariables',
								experimentVariables.filter((v) => v.key !== variable.key),
							);
							formik.setFieldTouched('experimentVariables', true);
						}}
					/>
				))}
			</Flex>

			<Flex direction="horizontal" align="center" spacing="xSmall">
				<IconEnvironment variant="small" />
				<Text type="small">Environment variables</Text>
			</Flex>

			<Table width="100%">
				<TableHead>
					<TableRow>
						<TableHeadCell width="auto">Key</TableHeadCell>
						<TableHeadCell width="auto">Value</TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{environmentVariables
						.slice()
						.sort((v1, v2) => localeCompare(v1.key, v2.key))
						.map((variable) => (
							<TableRow key={variable.key}>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.key}
									</Text>
								</TableDataCell>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.value}
									</Text>
								</TableDataCell>
							</TableRow>
						))}
					{environmentVariables.length === 0 && (
						<TableRow>
							<TableDataCell colSpan={3} sx={{ minHeight: '42px !important' }}>
								<Text style={{ color: theme.colors.neutral600 }}>No variables found.</Text>
							</TableDataCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Flex>
	);
}

interface EnvironmentVariableProps {
	environmentVariables: VariableVO[];
	experimentVariables: VariableVO[];
	variable: KeyValuePair;
	setVariable: (v: KeyValuePair) => void;
	onDelete?: () => void;
}

function EnvironmentVariable({
	environmentVariables,
	experimentVariables,
	variable,
	setVariable,
	onDelete,
}: EnvironmentVariableProps): ReactElement {
	const [key, setKey] = useState(variable.key);
	const [value, setValue] = useState(variable.value);
	const [isKeyEditing, setIsKeyEditing] = useState(false);
	const [isValueEditing, setIsValueEditing] = useState(false);

	const shadowsEnvironmentVariable = environmentVariables.find((v) => v.key === key);
	const shadowsExperimentVariable = !!experimentVariables.find((v) => v.key === key);

	return (
		<Flex spacing="xSmall">
			<Grid cols="2fr 3fr 40px" spacing="xSmall" align="center">
				<Flex direction="horizontal" style={{ width: '100%' }}>
					<PlaceholderMarker marker="{{" left small />
					<Tooltip
						content={shadowsExperimentVariable ? 'This key is already used.' : variable.key}
						onlyShowOnEllipsis={shadowsExperimentVariable ? false : true}
					>
						<TextField
							value={key}
							type="text"
							placeholder="Key"
							iconRight={shadowsEnvironmentVariable && !isKeyEditing && !isValueEditing ? IconInformation : undefined}
							iconRightTooltip="This experiment variable shadows an environment variable that has the same key."
							iconRightColor="slate"
							onChange={(e) => setKey(e.target.value)}
							onFocus={() => setIsKeyEditing(true)}
							onBlur={() => {
								setIsKeyEditing(false);
								if (value && !shadowsExperimentVariable) {
									setVariable({ key, value });
								}
							}}
							wrapperSx={{
								height: '32px',
							}}
							sx={{
								borderRadius: 0,
								borderLeft: shadowsExperimentVariable ? '1px solid red' : '1px solid white',
								borderRight: shadowsExperimentVariable ? '1px solid red' : '1px solid white',
								borderTopColor: shadowsExperimentVariable ? 'coral' : 'neutral300',
								borderBottomColor: shadowsExperimentVariable ? 'coral' : 'neutral300',
								px: '6px',
							}}
							style={{
								fontSize: '14px',
							}}
						/>
					</Tooltip>
					<PlaceholderMarker marker="}}" small />
				</Flex>

				<Tooltip content={variable.value} onlyShowOnEllipsis>
					<TextField
						hasError={key && !value ? true : false}
						value={value}
						type="text"
						placeholder="Value"
						onChange={(e) => setValue(e.target.value)}
						onFocus={() => setIsValueEditing(true)}
						onBlur={() => {
							setIsValueEditing(false);
							if (key && !shadowsExperimentVariable) {
								setVariable({ key, value });
							}
						}}
						wrapperSx={{
							height: '32px',
						}}
						sx={{
							px: '6px',
						}}
						style={{
							fontSize: '14px',
						}}
					/>
				</Tooltip>
				{onDelete && (
					<ButtonIcon variant="small" muted color="neutral600" onClick={onDelete} tooltip="Delete">
						<IconDelete />
					</ButtonIcon>
				)}
			</Grid>
			{shadowsEnvironmentVariable && (isKeyEditing || isValueEditing) && (
				<Flex
					direction="horizontal"
					spacing="xSmall"
					align="center"
					style={{
						p: 'xSmall',
						color: theme.colors.slate,
						backgroundColor: theme.colors.purple100,
						borderRadius: 'xxSmall',
						width: 'calc(100% - 62px)',
					}}
				>
					<IconInformation />
					<Text type="small"> This experiment variable shadows an environment variable that has the same key.</Text>
				</Flex>
			)}
		</Flex>
	);
}
