/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionVO,
	EnvironmentSummaryVO,
	ExperimentLaneVO,
	ExperimentStepActionVO,
	OccuranceVO,
	TemplateVO,
} from 'ui-api';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { ReactElement, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { suppressSubmitOnEnter } from 'utils/form';
import { Services } from 'services/services';
import { Form, Formik } from 'formik';
import { cloneDeep } from 'lodash';

import UnkownActionsTemplateModal from './UnknownActionsTemplateModal';
import LoadingTemplateModal from './LoadingTemplateModal';
import PlaceholderSyncJob from './PlaceholderSyncJob';
import ValidationHandler from './ValidationHandler';
import MetadataSyncJob from './MetadataSyncJob';

export type UseTemplateFormData = TemplateVO & {
	placeholdersMap: Map<string, OccuranceVO[]>;
	placeholderValuesMap: Map<string, string>;
	variablesMap: Map<string, OccuranceVO[]>;
	variableValuesMap: Map<string, string>;
	newExperimentTags?: string[];
	environmentId: string;
	variables: string[];
	__originalLanes: ExperimentLaneVO[];
	__originalExperimentName: string;
	__originalHypothesis: string;
};

interface UseTemplateFormLoadingHandlerProps {
	newExperimentTags?: string[];
	children: ReactElement;
	template?: TemplateVO;
	templateId: string;
	onSubmit: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export default function UseTemplateFormLoadingHandler({
	newExperimentTags,
	templateId,
	template,
	children,
	onSubmit,
	onClose,
}: UseTemplateFormLoadingHandlerProps): ReactElement {
	const templateResult = usePromise(
		() => (template ? Promise.resolve(template) : Services.templatesApi.getTemplate(templateId)),
		[templateId],
	);

	const actions = usePromise(() => Services.actions.fetchActions(), []);

	const { environmentsOfCurrentTeam: environments } = useEnvironments();
	if (environments.length === 0 || !templateResult.value || !actions.value) {
		return <LoadingTemplateModal onClose={onClose} />;
	}

	return (
		<UseTemplateActionsChecker
			newExperimentTags={newExperimentTags}
			template={templateResult.value}
			environments={environments}
			actions={actions.value}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			{children}
		</UseTemplateActionsChecker>
	);
}

interface UseTemplateActionsCheckerProps {
	withExperimentHyphotesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
	environments: EnvironmentSummaryVO[];
	newExperimentTags?: string[];
	children: ReactElement;
	template: TemplateVO;
	actions: ActionVO[];
	onSubmit: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export function UseTemplateActionsChecker({
	withExperimentHyphotesisExtraction = true,
	withExperimentNameExtraction = true,
	newExperimentTags,
	environments,
	template,
	children,
	actions,
	onSubmit,
	onClose,
}: UseTemplateActionsCheckerProps): ReactElement {
	const unknownActions: string[] = useMemo(() => {
		const unknownActions: string[] = [];
		const availableActionIds = new Set(actions.map((action) => action.id));
		for (let iL = 0; iL < template.lanes.length; iL++) {
			const { steps } = template.lanes[iL];
			for (let iS = 0; iS < steps.length; iS++) {
				const step = steps[iS];
				if (step.type === 'action') {
					const action = step as ExperimentStepActionVO;
					if (!availableActionIds.has(action.actionId)) {
						unknownActions.push(action.actionId);
					}
				}
			}
		}
		return unknownActions;
	}, [template?.id, actions]);

	if (unknownActions.length > 0) {
		return <UnkownActionsTemplateModal unknownActions={unknownActions} onClose={onClose} />;
	}

	return (
		<UseTemplateForm
			withExperimentHyphotesisExtraction={withExperimentHyphotesisExtraction}
			withExperimentNameExtraction={withExperimentNameExtraction}
			newExperimentTags={newExperimentTags}
			environmentId={environments[0]?.id}
			template={template}
			onSubmit={onSubmit}
		>
			{children}
		</UseTemplateForm>
	);
}

interface UseTemplateFormProps {
	withExperimentHyphotesisExtraction?: boolean;
	withExperimentNameExtraction?: boolean;
	newExperimentTags?: string[];
	children: ReactElement;
	environmentId: string;
	template: TemplateVO;
	onSubmit: (values: UseTemplateFormData) => void;
}

function UseTemplateForm({
	withExperimentHyphotesisExtraction,
	withExperimentNameExtraction,
	newExperimentTags,
	environmentId,
	template,
	children,
	onSubmit,
}: UseTemplateFormProps): ReactElement {
	const [__originalLanes] = useState(() => cloneDeep(template.lanes));
	const [__originalExperimentName] = useState(() => template.experimentName);
	const [__originalHypothesis] = useState(() => template.hypothesis);

	return (
		<Formik<UseTemplateFormData>
			initialValues={{
				...template,
				placeholderValuesMap: new Map(Array.from(template.placeholders).map(({ key }) => [key, ''])),
				variableValuesMap: new Map(),
				placeholdersMap: new Map(),
				variablesMap: new Map(),
				newExperimentTags,
				variables: [],
				environmentId,
				__originalLanes,
				__originalExperimentName,
				__originalHypothesis,
			}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			onSubmit={onSubmit}
		>
			<Form key={template.id} onKeyDown={suppressSubmitOnEnter} noValidate>
				<MetadataSyncJob
					withExperimentHyphotesisExtraction={withExperimentHyphotesisExtraction}
					withExperimentNameExtraction={withExperimentNameExtraction}
				/>
				<PlaceholderSyncJob />
				<ValidationHandler />
				{children}
			</Form>
		</Formik>
	);
}
