/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { Tooltip } from 'components';

interface CollapsedEntryProps {
	children: ReactElement;
	isSelected?: boolean;
	tooltip: string;
	onClick: () => void;
}

export default function CollapsedEntry({ isSelected, tooltip, children, onClick }: CollapsedEntryProps): ReactElement {
	return (
		<Tooltip content={tooltip}>
			<Flex
				align="start"
				justify="center"
				style={{
					backgroundColor: isSelected ? theme.colors.neutral150 : theme.colors.neutral000,
					borderRadius: 'xSmall',
					padding: '12px 20px',
					width: 'calc(100% - 40px)',

					onHover: {
						backgroundColor: isSelected ? theme.colors.neutral300 : theme.colors.neutral150,
					},
				}}
				onClick={onClick}
			>
				{children}
			</Flex>
		</Tooltip>
	);
}
