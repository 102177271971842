/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ExperimentFormValues as OldExperimentFormValues,
	PreparedFormData as OldPreparedFormData,
	prepareCopyExperimentForm,
} from 'pages/experiments/experiment';
import {
	DuplicateExperimentConfirm,
	DuplicateExperimentResult,
	duplicateExperimentConfirm,
} from 'pages/experiments/components/DuplicateExperimentConfirm';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { LoadingIndicator, Stack } from 'components';
import { useHistory, useLocation } from 'url/hooks';
import { ReactElement, ReactNode } from 'react';
import { Services } from 'services/services';
import { track } from 'tracking/sentry';
import { FormikErrors } from 'formik';
import { ExperimentVO } from 'ui-api';
import { set } from 'lodash';

import ExperimentEditorFormHandler, { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormActions, ExperimentFormValues } from './types';

export interface PreparedFormData {
	initialErrors: FormikErrors<ExperimentFormValues>;
	initialValues: ExperimentFormValues;
	actions: ExperimentFormActions[];
	experiment?: ExperimentVO;
	isNotFound?: boolean;
}

interface ExperimentEditorLoaderProps {
	experimentKeyCopy?: string;
	experimentKey?: string;
	children: ReactNode;
}

export default function ExperimentEditorLoader({
	experimentKeyCopy,
	experimentKey,
	children,
}: ExperimentEditorLoaderProps): ReactElement | null {
	const history = useHistory();
	const location = useLocation<{ preparedFormData?: PreparedFormData }>();

	const [preparedFormData, fetch] = useAsyncState(async () => {
		if (experimentKeyCopy) {
			const original = await Services.experiments.fetchExperiment(experimentKeyCopy);
			const duplicateResult: DuplicateExperimentResult = await duplicateExperimentConfirm(original);
			if (duplicateResult.result === 'cancel') {
				history.replace(`/experiments/edit/${experimentKeyCopy}/design`, 'redirect_after_duplicate');
				return;
			} else {
				const copiedExperimentFormData = await prepareCopyExperimentForm(
					experimentKeyCopy,
					duplicateResult,
					'UI_COPIED_LIST',
				);
				const result = await saveExperiment(map(experimentKeyCopy, copiedExperimentFormData.initialValues));
				if (result === null) {
					return;
				}

				const [, saved] = result;
				if (saved) {
					history.replace(
						`/experiments/${saved.teamKey}/edit/${saved.experimentKey}/design`,
						'redirect_after_duplicate',
					);
					return;
				} else {
					return mapForm(experimentKeyCopy, copiedExperimentFormData);
				}
			}
		}
		if (location?.state?.preparedFormData) {
			return location.state.preparedFormData;
		}
		if (experimentKey) {
			const preparedFormData: PreparedFormData = await prepareEditExperimentForm(experimentKey);
			if (preparedFormData.isNotFound) {
				history.replace('/experiments/', { showError: `Experiment ${experimentKey} not found` });
				return;
			}
			return preparedFormData;
		}
		history.replace('/experiments/start/');
	}, [experimentKey]);

	if (!preparedFormData.value || preparedFormData.loading) {
		return (
			<Stack
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<LoadingIndicator variant="xxLarge" color="slate" />
				<DuplicateExperimentConfirm />
			</Stack>
		);
	}

	if (!preparedFormData.value.initialValues) {
		track('Could not prepare experiment. This should not happen but I copied the place just in case.', {
			preparedFormData: preparedFormData.value,
		});
		console.log('Could not prepare experiment. This should not happen but I copied the place just in case.');
		return null;
	}

	return (
		<>
			<DuplicateExperimentConfirm />
			<ExperimentEditorFormHandler formData={preparedFormData.value} reloadExperiment={fetch}>
				{children}
			</ExperimentEditorFormHandler>
		</>
	);
}

const prepareEditExperimentForm = async (experimentKey: string): Promise<PreparedFormData> => {
	const InitialExperimentFormValues: ExperimentFormValues = {
		experimentKey: '',
		name: '',
		templateId: '',
		templateTitle: '',
		teamId: '',
		externalId: '',
		environmentId: '',
		hypothesis: '',
		webhookIds: [],
		lanes: [
			{
				id: '0',
				steps: [],
			},
		],
		tags: [],
		variables: [],
		experimentVariables: [],
		creationMethod: 'UI_FROM_SCRATCH',
		actions: [],
	};

	try {
		const original = await Services.experiments.fetchExperiment(experimentKey);
		const actions: ExperimentFormActions[] = [];
		const disabled = !original._actions.includes('edit');
		const variables = original.environmentId
			? await Services.environments.fetchEnvironmentVariables(original.environmentId)
			: [];
		const validation = await Services.experiments.validateExperiment({
			...original,
			variables: variables,
			creationMethod: 'UI_FROM_SCRATCH',
		});

		const initialErrors = {};
		validation.forEach(({ field, message }) => set(initialErrors, field, message));

		if (!disabled) {
			actions.push('save');
		}
		if (original._actions.includes('delete')) {
			actions.push('delete');
		}
		if (original._actions.includes('run')) {
			actions.push('run');
		}

		return {
			initialValues: {
				...InitialExperimentFormValues,
				...original,
				experimentKey,
				variables,
				actions,
			},
			initialErrors,
			actions,
			experiment: original,
		};
	} catch (error) {
		if (error?.response?.status === 404) {
			return { initialValues: InitialExperimentFormValues, initialErrors: {}, actions: ['save'], isNotFound: true };
		}
		track('Could not prepare experiment', { error });
		console.warn('Could not prepare experiment', error);
		return { initialValues: InitialExperimentFormValues, initialErrors: {}, actions: ['save'] };
	}
};

function map(experimentKey: string, values: OldExperimentFormValues): ExperimentFormValues {
	const { action, ...rest } = values;
	const actions: ExperimentFormActions[] = action ? ([values.action] as ExperimentFormActions[]) : [];
	return { ...rest, actions, experimentKey };
}

function mapForm(experimentKey: string, values: OldPreparedFormData): PreparedFormData {
	const { actions, ...rest } = values;
	return { ...rest, actions: actions, initialValues: map(experimentKey, values.initialValues) };
}
