/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory as ActionCategoryA, Grouping } from 'experiment/actions/useActionHierarchy';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import Labels from 'components/Select/Dropdown/presets/Labels';
import { ActionVO, TargetTypeDescriptionVO } from 'ui-api';
import { getGlobal, setGlobal } from 'utils/localStorage';
import { Flex } from '@steadybit/ui-components-lib';
import { IconAdvanced } from 'components/icons';
import { ReactElement, useState } from 'react';
import { theme } from 'styles.v2/theme';
import { ampli } from 'ampli';

import { ActionCategory, ActionCategoryItem } from './types';
import ActionGroup from './ActionGroup';

interface ActionsContentProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	selectedActionId?: string;
	collapsed?: boolean;
	actions: ActionVO[];
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionsContent({
	targetDefinitions,
	selectedActionId,
	collapsed,
	actions,
	onActionClick,
}: ActionsContentProps): ReactElement {
	const [selectedGrouping, setSelectedGrouping] = useState<Grouping>(
		getGlobal('experimentActionGrouping') === 'targetType' ? 'targetType' : 'technology',
	);
	const actionCategories = useActionHierarchy({ actions, grouping: selectedGrouping });

	return (
		<Flex
			className="experiment-collapsed-sidebar"
			style={{
				overflowY: 'auto',
				py: 'xSmall',
				px: collapsed ? 'none' : 'xSmall',
				width: collapsed ? 'calc(100% + 16px)' : undefined,
				paddingLeft: collapsed ? '0px' : 'xSmall',
			}}
		>
			<Flex style={{ width: '100%' }}>
				{!collapsed && (
					<DropDownButton
						variant="chromeless"
						value={selectedGrouping === 'targetType' ? 'Target Type' : 'Technology'}
						onValueChanged={(v) => {
							setSelectedGrouping(v as Grouping);
							setGlobal('experimentActionGrouping', v);
							ampli.experimentSidebarGrouped({
								group_by: v === 'targetType' ? 'legacy' : 'technology',
							});
						}}
						width="100%"
						icon={<IconAdvanced variant="small" ml="4px" />}
						sx={{
							cursor: 'pointer',
							border: '1px solid ' + theme.colors.neutral200,
							p: 'xSmall',
							marginBottom: '12px',
						}}
					>
						{({ width, selectItem }) => (
							<DropdownContentFrame>
								<Labels
									selectedId={selectedGrouping}
									type="loose"
									onSelect={({ id }) => selectItem(id)}
									labels={[
										{
											id: 'technology',
											label: 'Technology',
										},
										{
											id: 'targetType',
											label: 'Target Type',
										},
									]}
									width={width}
									withoutTooltip
								/>
							</DropdownContentFrame>
						)}
					</DropDownButton>
				)}

				{actionCategories.map((category) => (
					<ActionGroup
						targetDefinitions={targetDefinitions}
						selectedActionId={selectedActionId}
						group={mapCategory(category)}
						collapsed={collapsed}
						key={category.label}
						onActionClick={onActionClick}
					/>
				))}
			</Flex>
		</Flex>
	);
}

function mapCategory(category: ActionCategoryA): ActionCategory {
	return {
		...category,
		actions: category.actions
			? category.actions.map((a) => ({
					id: a.action.id,
					label: a.label,
				}))
			: undefined,
		subCategories: category.subCategories?.map((subCategory) => ({
			...subCategory,
			actions: subCategory.actions.map((a) => ({
				id: a.action.id,
				label: a.label,
			})),
		})),
	};
}
